import { Tabs } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { pModules } from "../../../configs/permissionTags";
import { LAYOUT_CONSTANTS } from "../state/reducer";
import { Constants } from '../../../common/utils/constants';

export default function SubmoduleTabs() {

    const moduleDetails = useSelector(state => state.layoutReducer?.moduleDetails);
    const selectedSubmodule = useSelector(state => state.layoutReducer?.selectedSubmodule);

    const userLocationMapping = useSelector(state => state.userLocationMappingReducer.userLocationMapping);
    const config = useSelector(state => state.app?.configurations);

    const opsAdminClmsRoutes = ["rvp-overview", "rvp-operation"];

    const franchisePartnerRoutes = ["daily-action", "live-drs", "lm-health", "reverse-image-audit", "rvp-prs"];

    const valmoLmMonitoringRoutes = ['performance', 'rvp-performance', 'fe-onboarding-status'];

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const navigateBetweenTab = key => {
        navigate(`${moduleDetails.path}/dashboard/${key}`);
    }

    useEffect(() => {
        const submodulePath = location.pathname.split("/")[4];
        dispatch({
            type: LAYOUT_CONSTANTS.UPDATE_SELECTED_SUBMODULE,
            payload: submodulePath
        });
    }, [location.pathname])

    const locationOpsType = localStorage.user ? JSON.parse(atob(localStorage.user)).location.locationOpsType : undefined;
    const user = localStorage.user ? JSON.parse(atob(localStorage.user)) : {};
    const configs = localStorage._configrations ? JSON.parse(atob(localStorage._configrations)) : {};

    return (
        <Tabs className="log10-tabs"
            activeKey={location?.search?.length > 0 ? selectedSubmodule + location.search : selectedSubmodule}
            onChange={navigateBetweenTab}>
            {
                moduleDetails && moduleDetails.children?.length > 0 &&
                moduleDetails.children?.map(tab => {
                    const userLocationMappingExistsForUser = userLocationMapping && Object.keys(userLocationMapping)?.length > 0;
                    var clusterKeys = userLocationMapping ? Object.keys(userLocationMapping) : [];
                    var lsnClm = false;
                    var lsnOpsAdmin = false;
                    var valmoClm = false;
                    var valmoOpsAdmin = false;
                    const opsAdminUserIds = config?.opsAdminUserIds || [];
                    const valmoOpsAdminUserIds = config?.valmoOpsAdminUserIds || [];
                    if (valmoOpsAdminUserIds.indexOf(user?.id + "") > -1) {
                        valmoOpsAdmin = true
                    }
                    else if (opsAdminUserIds.indexOf(user?.id + "") > -1) {
                        lsnOpsAdmin = true;
                    }
                    if (userLocationMappingExistsForUser) {
                        if (clusterKeys.length >= 1) {
                            const locations = userLocationMapping[clusterKeys[0]];
                            if (locations.find(l => l.source == 'LSN')) {
                                lsnClm = true;
                            }
                            else {
                                valmoClm = true;
                            }
                        }
                    }

                    if (valmoLmMonitoringRoutes.indexOf(tab.path) > -1 && (lsnClm || lsnOpsAdmin)) {
                        return undefined;
                    }

                    if (opsAdminClmsRoutes.indexOf(tab.path) > -1 && (!lsnClm && !lsnOpsAdmin)) {
                        return undefined;
                    }
                    if (franchisePartnerRoutes.indexOf(tab.path) > -1 && 
                        (!lsnClm && !lsnOpsAdmin && !user?.partner?.franchisePartner)) {
                        return undefined;
                    }
                    if (tab.name === pModules.monitoring_reports.subModule.sc_visibility && config?.enableSCVisibilityV2 !== 'true') {
                        return undefined;
                    }
                    if (locationOpsType != "SC" && tab.path == "cross-dock") {
                        return undefined;
                    }
                    if (tab.path == 'call-masking-adoption' && [1304655,1304682,1280833].indexOf(user?.id) < 0 ) {
                        return undefined;
                    }
                    if (['pod-audit', 'pending-suspicious-pods'].indexOf(tab.path) > -1 && 
                        (!configs?.podAuditEnabledLocations || configs?.podAuditEnabledLocations?.indexOf(Constants.userLocationId + "") < 0)
                        && !valmoClm && !valmoOpsAdmin)  {
                        return undefined;
                    }
                    if (tab.path == 'pod-audit-validation' &&  !valmoClm)  {
                        return undefined;
                    }
                    if (tab.path == 'live-drs-audit' &&  !lsnClm)  {
                        return undefined;
                    }
                    if (userLocationMapping)
                    return <Tabs.TabPane tab={<span className="text-semi-bold">{tab.label}</span>}
                        key={tab.path}
                    />
                })
            }
        </Tabs>
    )

}