import { Button, DatePicker } from "antd";
import moment from "moment";
import React from "react";
import AppliedFilters from "../../../common/applied-filters";
import SelectFilters from "../../../common/select-filters";
import { EXCEPTION_STATUS, EXCEPTION_TYPE } from "../state/constants"

export default function ExceptionsMenu({ filterObject, onFilterChange, resetFilters}) {

    const exceptionTypeOptions = Object.values(EXCEPTION_TYPE);

    const movementTypeOptions = [
        {
            value : "FORWARD",
            key   : "FORWARD",
            label : "Forward"
        },
        {
            value : "RTO",
            key   : "RTO",
            label : "RTO"
        }
    ];
    
    const statusOptions = Object.values(EXCEPTION_STATUS).filter(s => s.key !== "OPEN");

    return (
        <div className="menu-bg" >
            <div className="align-items-center flex-box flex-gap-l flex-wrap spacer-m">
                <DatePicker.RangePicker style={{ width : 275 }} className="pickup-date-filter font-size-m-1 border-radius-m"
                 onChange={value => {
                    if (!value || !value?.[0] || !value?.[1]) {
                        onFilterChange({
                            startDate     : moment().startOf("day").valueOf(),
                            endDate       : moment().endOf("day").valueOf()
                        })
                    }
                    else {
                        onFilterChange({
                            startDate     : value?.[0]?.valueOf(),
                            endDate       : value?.[1]?.valueOf()
                        })
                    }
                 }} separator={"to"} showTime={true} format="DD/MM/YYYY HH:mm"
                 value={[ moment(filterObject?.startDate), moment(filterObject?.endDate) ]} />

                <SelectFilters onChange={(key, value) => onFilterChange({ [key] : value })}
                 options={exceptionTypeOptions} placeholder={"Search Exception Type"} noLabelInValue={true}
                 filterKey={"exceptionType"} selectedOptions={filterObject?.exceptionType} />

                <SelectFilters onChange={(key, value) => onFilterChange({ [key] : value })}
                 options={movementTypeOptions} placeholder={"Search Movement Type"} noLabelInValue={true}
                 filterKey={"movementType"} selectedOptions={filterObject?.movementType} />

                <SelectFilters onChange={(key, value) => onFilterChange({ [key] : value })}
                 options={statusOptions} placeholder={"Search Exception Status"} noLabelInValue={true}
                 filterKey={"status"} selectedOptions={filterObject?.status} />

                <Button onClick={resetFilters} className="lsn-btn-error"
                 style={{ padding : "0 25px" }} >
                    Reset Filters
                </Button>
                <AppliedFilters showLabel={true}
                 filteredOptions={{
                    exceptionType  : filterObject?.exceptionType ? exceptionTypeOptions.filter(e => filterObject?.exceptionType.indexOf(e.key) > -1) : [],
                    movementType   : filterObject?.movementType ? movementTypeOptions.filter(m => filterObject?.movementType.indexOf(m.key) > -1) : [],
                    status         : filterObject?.status ? statusOptions.filter(s => filterObject?.status.indexOf(s.key) > -1) : [],
                 }}
                 keyToText={{
                    exceptionType : "Exception Type",
                    movementType  : "Movement Type",
                    status        : "Exception Status"
                 }} />
            </div>
        </div>
    )
}